<template>
<b-card class="m-0 border-0" no-body :class="card_classes ? card_classes : 'p-3'">
    <div class="search-wrapper mb-0">
        <b-row class="m-0 align-items-center">
            <b-col md class="col-xs pr-0">
                <div class='full-width p-0'>
                    <div :class="fullwidth_textinput ? '' : 'form-inline'">
                        <q-item v-if="!$q.screen.lt.sm" class="p-0 pr-3">
                            <q-item-section class="p-0">
                                <slot name="search_input"></slot>
                            </q-item-section>
                            <slot name="filters" v-if="no_filters !== true">
                                <q-item-section side class="p-0">
                                    <!-- Filter by active/inactive -->
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" :id="'customChk4'+form_type" @input="activeStatus" @click="eventBus.$emit('update_all')" value="active" :checked="active === 'active' ? 'active' : ''">
                                        <label class="custom-control-label" :for="'customChk4'+form_type">{{translate('active')}}</label>
                                    </div>
                                </q-item-section>
                                <q-item-section side class="p-0">
                                    <div class="custom-control custom-checkbox margin-left-10px">
                                        <input type="checkbox" class="custom-control-input" :id="'customChk5'+form_type" @input="inactiveStatus" @click="eventBus.$emit('update_all')" value="inactive" :checked="inactive === 'inactive' ? 'inactive' : ''">
                                        <label class="custom-control-label" :for="'customChk5'+form_type">{{translate('inactive')}}</label>
                                    </div>
                                </q-item-section>
                            </slot>
                            <slot name="type_filter"></slot>
                        </q-item>
                        <template v-if="$q.screen.lt.sm">
                            <q-item class="p-0" style="min-height: 0">
                                <q-item-section class="p-0">
                                    <slot name="search_input"></slot>
                                </q-item-section>
                            </q-item>
                            <slot name="filters" v-if="no_filters !== true">
                                <q-item class="p-0" style="min-height: 0; margin-left: -15px;">
                                    <q-item-section side class="p-0">
                                        <!-- Filter by active/inactive -->
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" :id="'customChk4'+form_type" @input="activeStatus" @click="eventBus.$emit('update_all')" value="active" :checked="active === 'active' ? 'active' : ''">
                                            <label class="custom-control-label" :for="'customChk4'+form_type">{{translate('active')}}</label>
                                        </div>
                                    </q-item-section>
                                    <q-item-section side class="p-0">
                                        <div class="custom-control custom-checkbox margin-left-10px">
                                            <input type="checkbox" class="custom-control-input" :id="'customChk5'+form_type" @input="inactiveStatus" @click="eventBus.$emit('update_all')" value="inactive" :checked="inactive === 'inactive' ? 'inactive' : ''">
                                            <label class="custom-control-label" :for="'customChk5'+form_type">{{translate('inactive')}}</label>
                                        </div>
                                    </q-item-section>
                                </q-item>
                            </slot>
                            <q-item v-if="type_filter===true" class="p-0" style="min-height: 0; margin-left: -21px;">
                                <slot name="type_filter"></slot>
                            </q-item>
                        </template>
                    </div>
                </div>
            </b-col>
            <b-col v-if="form_type" class="col-xs-auto text-right p-0" md="auto">
                <slot name="add_button"></slot>
            </b-col>
        </b-row>
    </div>
</b-card>
</template>

<script>
import {
    eventBus
} from "../../main"

export default {
    name: 'SearchWrapperCard',
    props: ['active', 'activeStatus', 'inactive', 'inactiveStatus', 'form_type', 'typeDisable', 'no_filters', 'fullwidth_textinput', 'card_classes', 'type_filter'],
    data: function () {
        return {
            eventBus: eventBus,
        }
    },
}
</script>
