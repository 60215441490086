<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="info" />
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="fas fa-file-csv" />&nbsp;
                    {{ translate('import_csv') }}: {{ panel_title }}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                    {{ translate('csv_format') }}
                </b-list-group-item>
                <b-list-group-item v-if="csv_fields && csv_fields.length > 0" class="list-group-item-accent-info">
                    <div class="csv-format alert alert-info m-0">
                        {{ translate('csv_format_fields_columns_count') }}: <strong>{{ csv_fields.length }}</strong><br />
                        {{ translate('csv_format_fields') }}: <strong>{{ csv_fields.join(' | ') }}</strong><br />
                        <em>{{ translate('header_csv_will_not_be_imported') }}</em>
                    </div>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                    {{ translate('upload_file') }}
                </b-list-group-item>
                <b-form @submit.prevent="importCSVFile">
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item tag="label" class="m-0 bg-primary" clickable v-ripple>
                            <q-item-section side>
                                <q-icon name="fas fa-file-csv" color="white" />
                            </q-item-section>
                            <q-item-section>{{ translate('choose_csv_file') }}</q-item-section>
                            <q-item-section class="hidden">
                                <b-form-file type="file" name="selectedFile" ref="selectedFile" @change="onFilesSelected" v-model="selectedFile">
                                </b-form-file>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                    <template v-if="selectedFile">
                        <b-list-group-item class="list-group-item-accent-success bg-green-2">
                            {{ translate('import_csv_file_you_are_about_to_import_this_file') }}:
                            <br />
                            <strong>{{ selectedFile.name }}</strong>
                            <br />
                            {{ translate('import_csv_file_please_make_sure_all_good') }}
                        </b-list-group-item>
                    </template>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn type="submit" no-caps color="info">
                                    <i class="fas fa-upload mr-1"></i> {{ translate('import') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                    <template v-if="importResults">
                        <q-separator />
                        <b-list-group-item class="list-group-item-accent-white">
                            <h3 class="text-h6">{{ translate('import_status') }}</h3>

                            <b-list-group-item class="list-group-item-accent-success bg-green-1">
                                {{ translate('imported_items') }}: <span class="text-positive">{{ importResults.imported_items }}</span>
                            </b-list-group-item>
                            <b-list-group-item class="list-group-item-accent-danger bg-red-1">
                                {{ translate('skipped_items') }}: <span class="text-negative">{{ importResults.skipped_items }}</span>
                            </b-list-group-item>
                        </b-list-group-item>
                    </template>
                </b-form>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from '../../../../main'

export default {
    name: 'ImportCSVFile',
    props: ['import_csv_post_url', 'panel_title', 'entity_type'],
    data: function () {
        return {
            selectedFile: null,
            selected: null,
            importResults: null,
        }
    },
    computed: {
        csv_fields: function () {
            let fields = []
            switch (this.entity_type) {
                case 'muscle':
                    fields = ['name', 'description']
                    break
                case 'unit':
                    fields = ['name', 'description']
                    break
                case 'medical_problem':
                    fields = ['name', 'description']
                    break
                case 'equipment':
                    fields = ['name', 'description']
                    break
                case 'objective':
                    fields = ['name', 'description']
                    break
                case 'training_category':
                    fields = ['name', 'description']
                    break
                case 'training_phase':
                    fields = ['name', 'description']
                    break
                case 'training_type':
                    fields = ['name', 'description']
                    break
                case 'training_level':
                    fields = ['name', 'description']
                    break
                case 'measurement':
                    fields = ['name', 'unit', 'description']
                    break
                case 'exercises_collection':
                    fields = ['name', 'training_phase', 'description']
                    break
                case 'training':
                    fields = ['name', 'training_level', 'training_type', 'description']
                    break
                case 'exercise':
                    fields = ['name', 'training_category', 'unit', 'muscles', 'equipments', 'recommended', 'not_recommended', 'forbidden', 'description']
                    break
                default:
                    break
            }

            return fields
        },
    },
    methods: {
        onFilesSelected: function (event) {
            this.selectedFile = event.target.files[0]
        },
        importCSVFile: function () {
            const formData = new FormData()

            if (this.selectedFile) {
                formData.append(`file`, this.selectedFile)
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }

            var url = this.import_csv_post_url ? this.import_csv_post_url : baseUrl + '404'

            axios.post(url, formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'import_csv_' + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        translated_message = this.translate(message + 'imported')
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })

                        // Emit event to update main list.
                        if (response.data.imported_items > 0) {
                            eventBus.$emit('update_main_list_' + this.entity_type)
                        }

                        // Update import reuslts.
                        this.updateImportResult(response.data)
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        updateImportResult: function (data) {
            this.importResults = data
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
