<template>
    <q-toggle :class="stacked === true ? 'stacked-label' : ''" v-if="toggle_status !== null" @input="toggleActive()" v-model="toggle_status" dense keep-color checked-icon="checked" unchecked-icon="clear" :label="label" />
</template>

<script>
import {
    eventBus
} from "../../main"

export default {
    name: 'ToggleActive',
    props: ['patch_toggle_route', 'item_id', 'entity_type', 'no_label', 'stacked', 'is_active'],
    data: function () {
        return {
            toggle_status: this.is_active ? true : false
        }
    },
    computed: {
        label: function () {
            if (this.no_label) {
                return null
            }
            return this.toggle_status ? this.translate('deactivate') : this.translate('activate')
        }
    },
    mounted: function () {
        eventBus.$on('update_toggle_' + this.entity_type, (item) => {
            if (item.id === this.item_id) {
                this.updateToggle(item)
            }
        })
    },
    methods: {
        updateToggle: function (item) {
            if (item.id !== null) {
                this.toggle_status = item.is_active ? true : false
            }
        },
        toggleActive: function () {
            var status = this.toggle_status
            var active = status ? 1 : 0
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                'is_active': active,
            }
            axios.patch(this.patch_toggle_route + '/' + this.item_id + '/is_active/' + active, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'toggle_status_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('toggle_active_' + this.entity_type, {
                            id: this.item_id,
                            is_active: active
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        this.toggle_status = false
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>
