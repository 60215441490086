<template>
    <div class="import-csv">
        <q-btn @click.stop.prevent="showImportCSVPanel()" v-if="!$q.screen.lt.sm" no-caps color="blue-10">
            <i class="fas fa-file-csv mr-1"></i> {{ translate('import_csv') }}
        </q-btn>
        <q-btn @click.stop.prevent="showImportCSVPanel()" v-if="$q.screen.lt.sm" dense round class="bg-white text-blue-10">
            <i class="fas fa-file-csv"></i>
        </q-btn>
    </div>
</template>

<script>
import ImportCSVFile from '../template-parts/forms/ImportCSVFile'

export default {
    name: 'ImportCSV',
    props: ['import_csv_post_url', 'panel_title', 'entity_type'],
    methods: {
        showImportCSVPanel: function () {
            const panelInstance = this.$showPanel({
                component: ImportCSVFile,
                props: {
                    import_csv_post_url: this.import_csv_post_url,
                    panel_title: this.panel_title,
                    entity_type: this.entity_type
                }
            })
        },
    }
}
</script>