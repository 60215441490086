<template>
<div>
    <template>
        <div v-if="!items || items.length == 0">
            <div class="text-muted">{{ translate('no_results') }}</div>
        </div>
        <div v-else>
            <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
        </div>
        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
            <template v-slot:prepend>
                <q-icon name="search" />
            </template>
            <template v-slot:append>
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddToIncludePanel" flat dense slot="add_button" color="dark" no-caps :label="add_button_text" icon="add" />
            </template>
        </q-input>
        <q-item tag="label" class="list-item full-width mb-0 p-0 text-primary" clickable v-ripple v-for="(item,i) in no_selection" :key="'single-choice-list-item-no-choice-selection-' + i">
            <q-item-section side class="p-0">
                <q-radio size="md" @input="selected_single_choice=null;emitUpdates(0, '')" flat color="primary" :id="'no-choice-selection'" :val="0" v-model="no_choice_selection" />
            </q-item-section>
            <q-item-section>
                <q-item-label>{{ item.label }}</q-item-label>
            </q-item-section>
        </q-item>
        <div class="items-container" :class="'single-choice-wrapper_scroll list_wraper-' + entity_type + '-' + item_id">
            <q-item tag="label" class="list-item full-width p-0" clickable v-ripple v-for="item in items" :key="'single-choice-list-item-' + entity_type + '-' + item_id + '-' + item.id">
                <q-item-section side class="p-0">
                    <q-radio size="md" @input="no_choice_selection=null;emitUpdates(item.id, item.name)" flat color="primary" :id="item.id" :val="item.id" v-model="selected_single_choice" />
                </q-item-section>
                <q-item-section>
                    <q-item-label>{{ item.name }}</q-item-label>
                </q-item-section>
            </q-item>
        </div>
        <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'included-items-' + entity_type + '-' + item_id + '-' +infItems" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import InfiniteLoading from 'vue-infinite-loading'
import AddToInclude from './AddItem'

export default {
    name: 'IncludeSingleChoice',
    props: ['get_route_to_include', 'item_id', 'placeholder_find_item', 'entity_type', 'field_name', 'initial_id', 'initial_name'],
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading
    },
    computed: {
        add_button_text: function () {
            return this.translate('new_' + this.get_route_to_include.replace(baseUrl, '').toLowerCase())
        },
        no_selection: function () {
            return [{id: null, name: '', label: this.translate('no_' + this.field_name)}]
        }
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            items: [],
            page: 1,
            state: {},
            infItems: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            selected_single_choice: parseInt(this.initial_id) !== 0 ? this.initial_id : null,
            no_choice_selection: !this.initial_id || this.initial_id === 0 ? 0 : null,
            eventBus: eventBus
        }
    },
    mounted: function () {
        eventBus.$on('update_selected_single_choice_' + this.entity_type + '_' + this.field_name, (item) => {
            this.updateSelectedSingleChoiceId(item)
        })
        eventBus.$on('update_all', () => {
            this.resetMainList()
        })
    },
    methods: {
        showAddToIncludePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddToInclude,
                props: {
                    add_item_title: this.add_button_text,
                    post_route: this.get_route_to_include ? this.get_route_to_include : this.post_route,
                    entity_type: this.entity_type,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_all')
                }
            })
        },
        updateSelectedSingleChoiceId: function (item) {
            this.selected_single_choice = item.id ? item.id : null
            this.no_choice_selection = item.id ? null : 0
        },
        emitUpdates: function (id, name) {
            eventBus.$emit('update_selected_single_choice_id_' + this.entity_type + '_' + this.field_name, {id: id, name: name})
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        getItems: function ($state) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
                (this.searchItem ? '&search=' + this.searchItem : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                            if (!this.selected_single_choice) {
                                this.emitUpdates(0, '')
                            }
                        }
                        this.items.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: calc(50px * 3);
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    .q-radio {
        transform: scale(0.85);
    }
}
body .search-input {
    width: calc(100% + 30px);
}
</style>
