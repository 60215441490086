<template>
<div class="animated fadeIn">
    <b-row class="m-0">
        <b-col class="p-0">
            <b-card class="m-0" no-body no-header>
                <div class="card-header bg-white pl-3 pr-3 pt-2 pb-0">
                    <q-item class="full-width m-0 p-0">
                        <q-item-section side class="p-0">
                            <h3 class="text-dark text-h6">{{ main_title }}</h3>
                        </q-item-section>
                    </q-item>
                </div>
                <search-wrapper-card :fullwidth_textinput="true" :active="item_active" :activeStatus="activeStatus" :inactive="item_inactive" :inactiveStatus="inactiveStatus" :form_type="'items-' + entity_type">
                    <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" :id="'filtername-item-' + entity_type" class="search-input" type="text" :placeholder="placeholder_find_item" :dense="true">
                        <template v-slot:prepend>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                    <template slot="add_button">
                        <q-item class="p-0">
                            <q-item-section side class="pr-2">
                                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddItemPanel">
                                    <i class="fa fa-plus mr-1"></i> {{ add_button_text }}
                                </q-btn>
                                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddItemPanel" />
                            </q-item-section>
                            <q-item-section side class="pr-0">
                                <import-csv :import_csv_post_url="import_csv_post_url" :panel_title="translate(panel_title ? panel_title : entity_type + 's')" :entity_type="entity_type" />
                            </q-item-section>
                        </q-item>
                    </template>
                </search-wrapper-card>
            </b-card>
            <div class="list-group" :class="'wrapper_library_item-' + entity_type">
                <q-item clickable v-for="(item,i) in items" :key="'library-item-' + i + '-' + item.id" class="list-item p-xs-0 full-width">
                    <q-item-section class="p-0" side>
                        <div class="image-thumbnail cursor-pointer" @click.prevent="showEditItemPanel(item.id, item.is_active)">
                            <i class="fa fa-photo fa-2x"></i>
                        </div>
                    </q-item-section>

                    <q-item-section>
                        <strong class="text-muted">{{ item.name }}</strong>
                    </q-item-section>

                    <template v-if="!$q.screen.lt.sm">
                        <!-- Optional Buttons -->
                        <slot name="buttons" :item_id="item.id"></slot>

                        <q-item-section side>
                            <q-btn round icon="edit" color="primary" @click="showEditItemPanel(item.id, item.is_active)">
                                <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn round icon="delete" color="danger" @click="showDeleteItemPanel(item.id)">
                                <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section v-if="patch_toggle_route" side class="full-height align-self-center pr-0 pt-2 pb-2">
                            <toggle-active :is_active="item.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="item.id" :entity_type="entity_type" />
                        </q-item-section>
                    </template>
                </q-item>
            </div>
            <infinite-loading :identifier="'infiniteLoading-' + entity_type + '-' + infItems" slot="append" @infinite="getItems" />
        </b-col>
    </b-row>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import AddItem from './forms/AddItem'
import EditItem from './forms/EditItem'
import DeleteItem from './forms/DeleteItem'
import ImportCSV from '../import/ImportCSV'
import ToggleActive from '../../template-parts/ToggleActive'

export default {
    name: 'LibraryItem',
    props: ['parent_post_route', 'entity_type', 'panel_title', 'get_route', 'patch_toggle_route', 'post_route', 'main_title', 'add_button_text', 'add_item_title', 'edit_item_title', 'delete_item_title', 'deleting_item_message', 'placeholder_find_item', 'field_unit', 'field_description'],
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'import-csv': ImportCSV,
        'toggle-active': ToggleActive,
    },
    computed: {
        import_csv_post_url: function () {
            return this.get_route + '/import'
        },
    },
    data: function () {
        return {
            items: [],
            searchQuery: '',
            page: 1,
            isTyping: false,
            infItems: +new Date(),
            state: {},
            item_active: 'active',
            item_inactive: '',
        }
    },
    mounted: function () {
        eventBus.$on('delete_' + this.entity_type, (id) => {
            this.deleteItem(id)
        })
        eventBus.$on('update_main_list_' + this.entity_type, () => {
            this.resetMainList()
        })
        eventBus.$on('toggle_active_' + this.entity_type, (item) => {
            this.toggleActiveItem(item)
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        updateLibraryItemName: function (item) {
            var item_id = item.item && item.item.id ? item.item.id : item.id
            var item_name = item.item && item.item.name ? item.item.name : item.name
            // Find index of specific object using findIndex method,
            var objIndex = this.items.findIndex((obj => obj.id === item_id))
            // and update the element from the items array.
            this.items[objIndex]['name'] = item_name ? item_name : ''
        },
        toggleActiveItem: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.items.findIndex((obj => obj.id === item.id))
                if (this.items[objIndex]) {
                    // And update is_active for the given item in list.
                    this.items[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.item_active === 'active' && this.item_inactive === '') {
                        this.deleteItem(item.id)
                    }
                    if (item.is_active === 1 && this.item_active === '' && this.item_inactive === 'inactive') {
                        this.deleteItem(item.id)
                    }
                }
            }
        },
        deleteItem: function (id) {
            if (id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.items.findIndex((obj => obj.id === id))
                // and remove the element from the items array.
                this.items.splice(objIndex, 1)
            }
        },
        activeStatus: _.debounce(function () {
            if (this.item_active === 'active') {
                this.item_active = ''
            } else {
                this.item_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.item_inactive === 'inactive') {
                this.item_inactive = ''
            } else {
                this.item_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        getItems: function ($state) {
            var url = this.get_route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            if (this.searchQuery) {
                url += '&search=' + this.searchQuery
            }
            if (this.item_active === 'active' && this.item_inactive === '') {
                url += '&status=' + this.item_active
            }
            if (this.item_inactive === 'inactive' && this.item_active === '') {
                url += '&status=' + this.item_inactive
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let items = this.items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        items.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.items = items
                        $state.complete()
                    }
                    eventBus.$emit('hideTabs')
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddItemPanel() {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_item_title,
                    post_route: this.parent_post_route ? this.parent_post_route : this.post_route,
                    entity_type: this.entity_type,
                    field_unit: this.field_unit,
                    field_description: this.field_description,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showEditItemPanel(id, is_active) {
            const panelInstance = this.$showPanel({
                component: EditItem,
                props: {
                    item_id: id,
                    edit_item_title: this.edit_item_title,
                    get_route: this.get_route,
                    post_route: this.post_route,
                    patch_toggle_route: this.patch_toggle_route,
                    entity_type: this.entity_type,
                    field_unit: this.field_unit,
                    field_description: this.field_description,
                    delete_item_title: this.delete_item_title,
                    deleting_item_message: this.deleting_item_message,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateLibraryItemName(result)
                    }
                })
        },
        showDeleteItemPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    delete_item_title: this.delete_item_title,
                    deleting_item_message: this.deleting_item_message,
                    post_route: this.post_route,
                    entity_type: this.entity_type
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>

